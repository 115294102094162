import graphql__1250e62602e239d6830a from "./__generated__/DittoPortalProviderQuery.graphql.ts";import { Ditto } from '@dittolive/ditto'
import { DittoLazyProvider, useOnlineIdentity } from '@dittolive/react-ditto'
import * as Sentry from '@sentry/browser'
import graphql from 'babel-plugin-relay/macro'
import dayjs from 'dayjs'
import React, { useContext, useEffect } from 'react'
import { fetchQuery } from 'react-relay'
import { useRelayEnvironment } from 'react-relay/hooks'

import { FullPageLoader } from '../components/loader'
import AppContext from '../context'
import { DITTO_PORTAL_APP } from '../scenes/appManagement/store/DittoIssuesProvider'
import { DittoPortalProviderQuery } from './__generated__/DittoPortalProviderQuery.graphql'
import { ensureDefaultAuthSettings } from './defaultAuthSettings'

const query = graphql__1250e62602e239d6830a

export const CREATED_AT_EXTRA_WAIT_CUTOFF_AGE_MS = 1 * 24 * 60 * 60 * 1000 // 1 day

type InternalDittoPortalProviderProps = {
  error: Error | null | undefined
  loading: boolean
  children: React.ReactElement<unknown>
}

/** Internal component used to manage the WASM loading state and to report load errors.*/
const InternalDittoPortalProvider = ({
  error,
  loading,
  children,
}: InternalDittoPortalProviderProps) => {
  useEffect(() => {
    if (error) {
      Sentry.withScope((scope) => {
        scope.setTag('ditto-instance-portal', 'instance-load-error')
        Sentry.captureException(error)
      })
    }
  }, [error])

  if (!!loading) {
    return <FullPageLoader />
  } else {
    return children
  }
}

type Props = {
  /** Child elements */
  children: React.ReactElement<unknown>
}

/** Cache used to hold all of the ditto instances created in the portal. */
const DittoPortalProvider = ({ children }: Props) => {
  const environment = useRelayEnvironment()
  const { create } = useOnlineIdentity()
  const { jwtToken } = useContext(AppContext)

  const createDittoFromPath = async (appId: string): Promise<Ditto | null> => {
    if (appId === DITTO_PORTAL_APP) {
      const windowEnvironment = window._env_
      const identity = create(
        {
          appID: appId,
          customAuthURL: `https://${windowEnvironment.PORTAL_DITTO_APP_HYDRA_WEBSOCKET_URL}`,
          enableDittoCloudSync: false,
        },
        appId,
      )

      return new Ditto(identity, appId)
    }

    try {
      const result = await fetchQuery<DittoPortalProviderQuery>(
        environment,
        query,
        { appId },
        { networkCacheConfig: { force: true } },
      ).toPromise()
      const cloudUrls = result?.viewer.appById?.cloudUrls || []
      const cloudDomain = cloudUrls.length ? cloudUrls[0] : undefined

      if (!cloudDomain) {
        throw new Error('No domain was found for the app')
      }

      const identity = create(
        {
          appID: appId,
          customAuthURL: cloudDomain ? `https://${cloudDomain.url}` : '',
          enableDittoCloudSync: false,
        },
        appId,
      )
      const ditto = new Ditto(identity, appId)

      // Authenticate
      const token = await jwtToken()
      const authenticatorParams: [string, string] =
        process.env.NODE_ENV === 'development'
          ? ['full_access', 'dummy-provider']
          : [token || '', 'portal']

      const isNewApp = dayjs(result?.viewer.appById?.createdAt).isAfter(
        dayjs().subtract(CREATED_AT_EXTRA_WAIT_CUTOFF_AGE_MS, 'ms'),
      )
      const verifyParams = {
        retryCount: isNewApp ? 240 : 10,
      }

      await ensureDefaultAuthSettings(
        appId,
        cloudDomain.url,
        authenticatorParams,
        ditto.siteID.toString(),
        verifyParams,
      )

      await ditto.auth.loginWithToken(...authenticatorParams)
      await ditto.smallPeerInfo.setSyncScope('BigPeerOnly')
      ditto.smallPeerInfo.isEnabled = false

      return ditto
    } catch (instanceError) {
      Sentry.withScope((scope) => {
        scope.setTag('instance-error', 'instance-error')
        Sentry.captureException(instanceError)
      })

      throw instanceError
    }
  }

  return (
    <DittoLazyProvider
      setup={createDittoFromPath}
      // initOptions={{ webAssemblyModule: '/ditto.wasm' }}
    >
      {({ loading, error }) => {
        return (
          <InternalDittoPortalProvider error={error} loading={loading}>
            {children}
          </InternalDittoPortalProvider>
        )
      }}
    </DittoLazyProvider>
  )
}

export default DittoPortalProvider
