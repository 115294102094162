import graphql__bb8ef2bc3ac08ac4977c from "./__generated__/useActiveDomainQuery.graphql.ts";import * as Sentry from '@sentry/browser'
import axios from 'axios'
import graphql from 'babel-plugin-relay/macro'
import { useCallback, useEffect, useRef, useState } from 'react'
import { fetchQuery } from 'react-relay'
import { useRelayEnvironment } from 'react-relay/hooks'

import { useActiveDomainQuery } from './__generated__/useActiveDomainQuery.graphql'

const query = graphql__bb8ef2bc3ac08ac4977c

type DomainEntry = {
  readonly url: string
}

export const REPORT_ERROR_AFTER_DOMAIN_REPORTED_NOT_READY_FOR_SECS = 120
export const POLL_INTERVAL_SECS = 2

/** Helper hook to retrieve an active domain for an app ID and to poll the backend
 * for the app domain status when no domain is found in ready state. */
const useActiveDomain = (
  appId: string,
): {
  isDomainInitiallyReady: boolean
  domain: string | undefined
  error: boolean
  queryAttempts: number
} => {
  const environment = useRelayEnvironment()
  const didReportTimeout = useRef(false)
  const domainCheckTimeoutRef = useRef<number>()
  const [foundDomains, setFoundDomains] = useState<readonly DomainEntry[]>([])
  const [readyDomains, setReadyDomains] = useState<readonly DomainEntry[]>([])
  const [queryAttempts, setQueryAttempts] = useState(0)
  const [isDomainInitiallyReady, setIsDomainInitiallyReady] = useState(true)

  const domain = readyDomains.length ? readyDomains[0] : undefined
  const didExceedWaitingTime =
    queryAttempts * POLL_INTERVAL_SECS >=
    REPORT_ERROR_AFTER_DOMAIN_REPORTED_NOT_READY_FOR_SECS

  const isDomainReady = useCallback(
    async ({ url }: DomainEntry) => {
      const basePath =
        process.env.NODE_ENV === 'development' ? `${url}/${appId}` : `${url}`
      try {
        const response = await axios.get(`https://${basePath}/api/v3/info`)
        return response.status >= 200 && response.status < 300
      } catch {
        return false
      }
    },
    [appId],
  )

  const loadDomains = useCallback(async () => {
    const result = await fetchQuery<useActiveDomainQuery>(
      environment,
      query,
      { appId },
      { networkCacheConfig: { force: true } },
    ).toPromise()
    return result?.viewer?.appById?.cloudUrls ?? []
  }, [appId, environment])

  const updateDomainStatus = useCallback(async () => {
    domainCheckTimeoutRef.current = undefined
    setQueryAttempts((current) => current + 1)

    let domains: readonly DomainEntry[]
    if (foundDomains.length) {
      domains = foundDomains
    } else {
      const newFoundDomains = await loadDomains()
      setFoundDomains(newFoundDomains)
      domains = newFoundDomains
    }

    const newReadyDomains = []
    for (const domain of domains) {
      if (await isDomainReady(domain)) {
        newReadyDomains.push(domain)
      }
    }

    setReadyDomains(newReadyDomains)
    return newReadyDomains
  }, [isDomainReady, foundDomains, loadDomains])

  useEffect(() => {
    const initialize = async () => {
      if (!queryAttempts) {
        const initialDomains = await updateDomainStatus()
        setIsDomainInitiallyReady(!!initialDomains.length)
      }
    }

    initialize()
  }, [queryAttempts, updateDomainStatus])

  useEffect(
    function recheckDomainStatusIfNoDomain() {
      if (!domain && queryAttempts > 0) {
        // Cancel any pending timeout.
        if (domainCheckTimeoutRef.current) {
          clearTimeout(domainCheckTimeoutRef.current)
        }

        domainCheckTimeoutRef.current = window.setTimeout(
          updateDomainStatus,
          POLL_INTERVAL_SECS * 1000,
        )
      }

      return () => {
        if (domainCheckTimeoutRef.current) {
          clearTimeout(domainCheckTimeoutRef.current)
        }
      }
    },
    [domain, queryAttempts, updateDomainStatus],
  )

  useEffect(
    function reportIngressTimeout() {
      if (didExceedWaitingTime && !didReportTimeout.current) {
        Sentry.withScope((scope) => {
          scope.setTag('app-domain-timeout', appId)
          Sentry.captureException(
            new Error(
              `A domain was not started for in ${REPORT_ERROR_AFTER_DOMAIN_REPORTED_NOT_READY_FOR_SECS} seconds`,
            ),
          )
        })
        didReportTimeout.current = true
      }
    },
    [queryAttempts, didReportTimeout, appId, didExceedWaitingTime],
  )

  return {
    queryAttempts,
    isDomainInitiallyReady,
    domain: domain?.url,
    error: didExceedWaitingTime && !domain,
  }
}

export default useActiveDomain
